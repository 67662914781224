//import { Loading } from 'quasar'

//import { useQuasar } from 'quasar'
//const $q = useQuasar()

var loader = {
    show: function(options) {
        /*Loading.show({
         //delay: 400 // ms
        })*/
    },
    
    hide: function(options) {
        //Loading.hide()
    }
}


export {
    loader
}