<template>
  <div class="container mx-auto px-4 h-full">
    <div class="flex content-center items-center justify-center h-full">
      <div class="w-full lg:w-4/12 px-4">
        <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
          <div class="flex-auto px-4 lg:px-10 py-10">
            <div class="text-center mb-3">
              <h6 class="text-blueGray-500 text-sm font-bold">
                Sign in
              </h6>
              
            </div>
            <form @submit.prevent="login" novalidate="true">
              <Input
                label="email"
                type="email"
                v-model.trim="data.form.email"
                placeholder="Email"
              />

              <Input
                label="password"
                type="password"
                v-model.trim="data.form.password"
                placeholder="Password"
              />

              <!--<div>
                <label class="inline-flex items-center cursor-pointer">
                  <input
                    id="customCheckLogin"
                    type="checkbox"
                    class="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                  />
                  <span class="ml-2 text-sm font-semibold text-blueGray-600">
                    Remember me
                  </span>
                </label>
              </div>-->

              <div class="text-center mt-6">
                <button
                  class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                  type="submit"
                  
                >
                  Sign In
                </button>
              </div>



            </form>
          </div>
        </div>
        <div class="flex flex-wrap mt-6 relative">
          <div class="w-1/2">
            <a href="javascript:void(0)" class="text-blueGray-200">
              <small>Forgot password?</small>
            </a>
          </div>
          <div class="w-1/2 text-right">
            <router-link to="/auth/register" class="text-blueGray-200">
              <small>Report a problem</small>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { reactive, computed } from 'vue'
  import { useRouter } from 'vue-router'

  import { dialog } from "@/utils/dialog";
  import { loader } from "@/utils/loader";

  import Input from "@/components/Input";
  //import { NButton } from 'naive-ui'

  const router = useRouter()
  const data = reactive({
    form: {
      email: '',
      password: '',
    },
    /*countMultiplied: computed(function() {
      return data.count * 2
    })*/
  })

  function login() {
    //count.value++;
    loader.show()

    setTimeout(() => {
      router.push("/admin/dashboard");
      loader.hide()
    }, 1000);
    /*dialog({
      message: "Logging in as " + data.form.email,
    })
    router.push("/admin/dashboard");*/
    //data.count++
  }
</script>

<script>
//import github from "@/assets/img/github.svg";
//import google from "@/assets/img/google.svg";
/*
import { NButton } from 'naive-ui'
import Input from "@/components/Input";
import Dialog from "@/mixins/Dialog";

export default {
  components: { NButton, Input },
  mixins: [ Dialog ],
  data: () => ({
    count: 0
  }),
  methods: {
    login: function() {
      this.count++;
      //this.dialog({
      //  message: "Cool"
      //})
      //this.$router.push("/admin/dashboard");
    },
  }
};*/
</script>
