<template>
    <div class="relative w-full mb-3">
    <label
        class="block uppercase text-blueGray-600 text-xs font-bold"
    >
        {{ label }}
    
    <input
        :type="type"
        :value="modelValue" 
        @input="$emit('update:modelValue', $event.target.value)"

        class="border-0 mt-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
        :placeholder="placeholder"
    />
    </label>
    </div>
</template>

<script setup>
    /*import { defineProps } from 'vue'

    const props = defineProps(["label", "placeholder", "type", "error", "bind", "modelValue"])*/
</script>

<script>
    export default {
        props: ["label", "placeholder", "type", "error", "bind", "modelValue"]
    }
</script>
