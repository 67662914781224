<template>
  <div>
    <navbar />



      <fs-lightbox
        :toggler="data.galleryToggle"
        :slide="data.galleryIndex"
        :sources="data.media"
        v-if="data.media.length"
      />



    <main class="profile-page">

      <!---------------- Background header ---------------->
      <section class="relative block h-500-px">
        <div
          class="absolute top-0 w-full h-full bg-center bg-cover"
          :style="{
            backgroundColor: 'cyan', // cyan
            backgroundImage: data.backgroundImageUrl,
          }"
        >
          <span
            id="blackOverlay"
            class="w-full h-full absolute opacity-50 bg-black"
            style="opacity: 50%"
          ></span>
        </div>

        <!-- Curved line -->
        <div
          class="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
          style="transform: translateZ(0);"
        >
          <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 3560 100"
            x="0"
            y="0"
          >
            <polygon
              class="text-blueGray-200 fill-current"
              points="3560 0 3560 100 0 100"
            ></polygon>
          </svg>
        </div>
      </section>



      <!---------------- Content section ---------------->
      <section class="relative py-16 bg-blueGray-200">
        <div class="container mx-auto px-4">
          <div
            class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-64"
          >
            <div class="px-6">
              <div class="flex flex-wrap justify-center" v-if="data.media?.length">
                <div
                  class="w-full lg:w-3/12 px-4 lg:order-2 flex justify-center"
                >
                  <div class="relative">

                    <!---------------- Round image ---------------->
                    <!--<img
                      alt="..."
                      :src="team2"
                      class="shadow-xl rounded-full h-auto align-middle border-none absolute -m-16 -ml-20 lg:-ml-16 max-w-150-px place-content-center"
                    />-->
                    <div
                      class="shadow-xl align-middle border-none absolute bg-center bg-cover rounded-lg grid place-content-center"
                      :style="{
                        backgroundImage: data.firstImageUrl,
                        //width: '400px',
                        //height: '250px',
                        //top: '-125px',
                        //left: '-200px'
                        width: '500px',
                        height: '300px',
                        top: '-150px',
                        left: '-250px',
                        cursor: 'pointer',
                      }"
                      @click="viewMedia()"
                      v-if="data.media?.length"
                    >
                      <!--<span
                        id="blackOverlay"
                        class="w-full h-full absolute bg-black rounded-lg"
                        style="opacity: 50%"
                      ></span>-->
                      
                      <!--<i class="fas fa-play-circle text-5xl " style="color: #fff; opacity: 80%"></i>-->
                      <!--<i class="fas fa-play text-5xl " style="color: #fff; opacity: 70%; position: absolute; bottom: 45%; left: 45%"></i>-->
                      <i class="fas fa-play-circle text-5xl " style="color: #fff; opacity: 70%;" v-if="data.event?.video?.length"></i>
                      <!--<i class="fas fa-play-circle text-4xl " style="color: #fff; opacity: 70%; position: absolute; bottom: 20px; left: 20px"></i>-->
                    </div>

                    
                  </div>
                </div>
                <div
                  class="w-full lg:w-4/12 px-4 lg:order-3 lg:text-right lg:self-center"
                >
                  <div class="py-6 px-3 mt-32 sm:mt-0">
                    <!--<button
                      class="bg-emerald-500 active:bg-emerald-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                      type="button"
                    >
                      Dans
                    </button>-->
                  </div>

                  
                </div>

                
                <div class="w-full lg:w-4/12 px-4 lg:order-1">

                  <div class="flex justify-center py-4 lg:pt-4 pt-8">
                    &nbsp;
                    <!--<div class="mr-4 p-3 text-blueGray-600 text-center">
                      <i class="fas fa-map-marker-alt mr-2 text-lg text-blueGray-400"></i>
                      <strong>Premiär</strong><br>{{ data.event?.premiar_datum }} på {{ data.event?.premiar_scen }}
                    </div>

                    <div class="mr-4 p-3 text-blueGray-600 text-center">
                      <i class="fas fa-clock mr-2 text-lg text-blueGray-400"></i>
                      <strong>Längd</strong><br>{{ data.event?.langd }} minuter
                    </div>

                    <div class="mr-4 p-3 text-blueGray-600 text-center">
                      <i class="fas fa-palette mr-2 text-lg text-blueGray-400"></i>
                      <strong>Genre</strong><br>{{ data.event?.genre }}
                    </div>-->

                    <!--<div class="mr-4 p-3 text-center">
                      <span
                        class="text-xl font-bold block uppercase tracking-wide text-blueGray-600"
                      >
                        {{ data.event?.bilder?.length }}
                      </span>
                      <span class="text-sm text-blueGray-400">Foton</span>
                    </div>

                    <div class="mr-4 p-3 text-center">
                      <span
                        class="text-xl font-bold block uppercase tracking-wide text-blueGray-600"
                      >
                        {{ data.event?.video?.length }}
                      </span>
                      <span class="text-sm text-blueGray-400">Videor</span>
                    </div>

                    <div class="mr-4 p-3 text-center">
                      <span
                        class="text-xl font-bold block uppercase tracking-wide text-blueGray-600"
                      >
                        {{ data.event?.trycksaker?.length }}
                      </span>
                      <span class="text-sm text-blueGray-400">Trycksaker</span>
                    </div>-->

                  </div>

                </div>

                
              </div>
            
              <div class="text-center mt-20" :class="{ 'pt-16': data.media?.length }">
                <h3
                  class="text-4xl font-semibold leading-normal text-blueGray-700 mb-1"
                >
                  {{ data.event?.titel }}
                </h3>

                <div class="text-lg leading-normal mt-0 mb-2 text-blueGray-500 font-semibold ">
                  <a href="#">{{ data.event?.medverkandeAnsvarig?.[0].namn }}</a>
                </div>

                <!--<div class="mb-2 text-blueGray-600 mt-10">
                  <i class="fas fa-map-marker-alt mr-2 text-lg text-blueGray-400"></i>
                  <strong>Premiär</strong><br>{{ data.event?.premiar_datum }} på {{ data.event?.premiar_scen }}
                </div>

                <div class="mb-2 text-blueGray-600">
                  <i class="fas fa-clock mr-2 text-lg text-blueGray-400"></i>
                  <strong>Längd</strong><br>{{ data.event?.langd }} minuter
                </div>

                <div class="mb-2 text-blueGray-600">
                  <i class="fas fa-palette mr-2 text-lg text-blueGray-400"></i>
                  <strong>Genre</strong><br>{{ data.event?.genre }}
                </div>-->

              </div>


              <div class="flex justify-center py-4 lg:pt-4 pt-8">

                <div class="mr-4 p-3 text-blueGray-500 text-center whitespace-nowrap">
                  <i class="fas fa-calendar mr-2 text-lg text-blueGray-400"></i>
                  <strong>Premiär</strong>
                  <p class="whitespace-nowrap text-blueGray-600">{{ data.event?.premiar_datum }}</p>
                </div>

                <div class="mr-4 p-3 text-blueGray-500 text-center whitespace-nowrap">
                  <i class="fas fa-map-marker-alt mr-2 text-lg text-blueGray-400"></i>
                  <strong>Scen</strong>
                  <p class="whitespace-nowrap text-blueGray-600">{{ data.event?.premiar_scen }}</p>
                </div>

                <div class="mr-4 p-3 text-blueGray-500 text-center whitespace-nowrap" v-if="data.event?.langd > 0">
                  <i class="fas fa-clock mr-2 text-lg text-blueGray-400"></i>
                  <strong>Längd</strong>
                  <p class="whitespace-nowrap text-blueGray-600">{{ data.event?.langd }} minuter</p>
                </div>

                <div class="mr-4 p-3 text-blueGray-500 text-center whitespace-nowrap">
                  <i class="fas fa-palette mr-2 text-lg text-blueGray-400"></i>
                  <strong>Genre</strong>
                  <p class="whitespace-nowrap text-blueGray-600">{{ data.event?.genre }}</p>
                </div>
              </div>



              <div class="mt-10 py-10 border-t border-blueGray-200 text-center">
                <div class="flex flex-wrap justify-center">
                  <div class="w-full lg:w-9/12 px-4">
                    <p
                      class="mb-4 text-lg leading-relaxed text-blueGray-700"
                      v-html="data.event?.ingress"
                    >
                    </p>

                    <div v-if="data.showMore">
                      <p
                        class="mb-4 text-lg leading-relaxed text-blueGray-700"
                        v-html="data.event?.text"
                      >
                      </p>
                    </div>

                    <a
                      href="javascript:void(0)"
                      class="font-normal text-emerald-500"
                      @click="data.showMore = !data.showMore"
                    >
                      Visa mer
                    </a>



                  </div>
                </div>
              </div>


              <div class="mt-10 py-10 border-t border-blueGray-200 text-center">
                <h2 class="text-xl font-semibold">Medverkande</h2>


                <dl v-for="(medverkande) in data.event?.medverkande" :key="medverkande.id">
                  <dt class="text-blueGray-600">
                    {{ medverkande.roll }}
                  </dt>
                  <dd class="text-blueGray-500">
                    <a href="#">{{ medverkande.namn }}</a>
                  </dd>
                  
                </dl>
              </div>


              <div class="mt-10 py-10 border-t border-blueGray-200 text-center">
                <h2 class="text-xl font-semibold">Bilder</h2>

                
              </div>

              <div class="mt-10 py-10 border-t border-blueGray-200 text-center">
                <h2 class="text-xl font-semibold">Filmer</h2>

                
              </div>

              <div class="mt-10 py-10 border-t border-blueGray-200 text-center">
                <h2 class="text-xl font-semibold">Trycksaker</h2>

                
              </div>


            </div>
          </div>
        </div>
      </section>
    </main>


    <footer-bottom />
  </div>
</template>

<script setup>
  import { reactive, computed, onMounted, onBeforeMount } from 'vue'
  import { useRoute } from 'vue-router'

  import Navbar from "@/components/Navbar.vue";
  import FooterBottom from "@/components/Footer.vue";

  import team2 from "@/assets/img/team-2-800x800.jpg";
  //import { NImage } from 'naive-ui'

  import FsLightbox from "fslightbox-vue/v3";

  const route = useRoute()
  const data = reactive({
    galleryToggle: false,
    galleryIndex: 1,
    isLoading: true,
    showMore: false,
    event: { "title": "" },
    media: [],
    index: null,
    backgroundImageUrl: computed(function() {
      var url = data.event?.bilder?.[1]?.url ? `url(${ data.event?.bilder?.[1]?.url })` : undefined
      if (!url) {
        url = data.firstImageUrl;
      }
      if (!url) {
        url = "linear-gradient(to right, cyan , darkcyan)";
      }
      return url
    }),
    firstImageUrl: computed(function() {
      return data.event?.bilder?.[0]?.url ? `url(${ data.event?.bilder?.[0]?.url })` : undefined
    })
  })

  onBeforeMount(async () => {
    const response = await fetch("https://www.scenarkivet.se/api/?request=uppsattning&id=" + route.params.id);
    const responseJson = await response.json();
    data.event = responseJson.uppsattning;
    data.isLoading = false;
    //data.event = (await response.json()).uppsattning;

    document.title = data.event.titel + " | Scenarkivet";

    // Loop through data.event.bilder and add video.url to data.items
    /*data.media = data.event.bilder.map(item => {
      return {
        type: 'image',
        thumb: item.url,
        src: item.url,
      };
    });*/
    data.media = data.event.video.map(item => {
      return item.url;
    });
    data.media.push(...data.event.bilder.map(item => {
      return item.url;
    }));
  });

  function viewMedia() {

    //alert("Clicked!");

    // https://fslightbox.com/vue/documentation/events
    // https://github.com/banthagroup/fslightbox
    data.galleryToggle = !data.galleryToggle;
    data.galleryIndex = 1;
  }

  /*import { reactive, computed, onMounted } from 'vue'
  import { useRouter } from 'vue-router'

  import { dialog } from "@/utils/dialog";
  import { loader } from "@/utils/loader";

  import Input from "@/components/Input";
  //import { NButton } from 'naive-ui'

  

  function login() {
    //count.value++;
    loader.show()

    setTimeout(() => {
      router.push("/admin/dashboard");
      loader.hide()
    }, 1000);
    //dialog({
    //  message: "Logging in as " + data.form.email,
    //})
    //router.push("/admin/dashboard");
    //data.count++
  }*/
</script>

<style>


  .fslightbox-source {
    width: 100% !important;
    height: 90vh !important;
    object-fit: contain;
  }

  .fslightbox-video {
    background-color: black;
  }

  .fslightbox-toolbar-button:first-child {
    display: none !important;
  }
</style>